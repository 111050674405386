import React from 'react';
import Helmet from '../project/Helmet';
import { withPrefix } from 'gatsby';
import {Trans, t} from '@lingui/macro';


/**
 * @fero
 */

class CommercialTermsPage extends React.PureComponent {
    render() {
        const pdf = withPrefix("/pdf/obchodne-podmienky.pdf");
        return <React.Fragment>
            <Helmet
                title={t`Obchodné podmineky`}
            />
            <div className="full-size-height">
                <object data={pdf} type="application/pdf" width="100%" height="100%">
                    <p>
                        <Trans>Pre zobrazenie obchodných podmienok prejdite na </Trans>
                        <a href={pdf}><Trans>PDF.</Trans></a>
                    </p>
                </object>
            </div>
        </React.Fragment>;
    }

}

export default CommercialTermsPage;